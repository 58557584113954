import $ from 'jquery';
//attach jquery to dom
(function ($) {

  var Sage = {
    // All pages
    'common': {
      init: function () {

        var site_url = MyAjax.ajaxurl.replace("wp-admin/admin-ajax.php", "");

        var ms_ie = false;
        var ua = window.navigator.userAgent;
        var old_ie = ua.indexOf('MSIE ');
        var new_ie = ua.indexOf('Trident/');

        if ((old_ie > -1) || (new_ie > -1)) {
          ms_ie = true;
        }

        //BEGIN VIEWPORT MODIFICATIONS
        if ($(window).width() < 500) {
          //$("meta[name='viewport']").attr("content", "width=500");
          var dif = 500 - $(window).width(),
            one_percent = 500 / 100,
            percentage_to_change_by = dif / one_percent,
            percentage_to_decimal = percentage_to_change_by / 100,
            final_updated_viewport_scale = 1 - percentage_to_decimal;
          $("meta[name='viewport']").attr("content", "width=500, initial-scale=" + final_updated_viewport_scale.toFixed(2) + ", minimum-scale=" + final_updated_viewport_scale.toFixed(2));
        }

        var protection_width = $(window).width(),
          min_enforced_width_for_mobile = 500,
          update_viewport = function () {
            var triggered_resize_with = $(window).width();
            //console.log("triggered_resize_with: "+triggered_resize_with);
            if (protection_width !== triggered_resize_with && triggered_resize_with !== min_enforced_width_for_mobile) {
              protection_width = triggered_resize_with;

              if (triggered_resize_with < min_enforced_width_for_mobile) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening

                var dif = min_enforced_width_for_mobile - protection_width,
                  one_percent = min_enforced_width_for_mobile / 100,
                  percentage_to_change_by = dif / one_percent,
                  percentage_to_decimal = percentage_to_change_by / 100,
                  final_updated_viewport_scale = 1 - percentage_to_decimal;

                $("meta[name='viewport']").attr("content", "width=500, initial-scale=" + final_updated_viewport_scale.toFixed(2) + ", minimum-scale=" + final_updated_viewport_scale.toFixed(2));
              } else {
                //alert(triggered_resize_with);
                $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=1");
              }
              $(window).off("resize.update_viewport");
              setTimeout(function () {
                $(window).on("resize.update_viewport", update_viewport);
              }, 400);
            }
          };
        $(window).on("resize.update_viewport", update_viewport);
        //END VIEWPORT MODIFICATIONS

        //BEGIN SAME HEIGHT FUNCTION
        $.fn.sameHeight = function () {
          var selector = this;
          var heights = [];

          // Save the heights of every element into an array
          selector.each(function () {
            var height = $(this).height();
            heights.push(height);
          });

          // Get the biggest height
          var maxHeight = Math.max.apply(null, heights);
          // Show in the console to verify
          //console.log(heights,maxHeight);

          // Set the maxHeight to every selected element
          selector.each(function () {
            $(this).height(maxHeight);
          });
        };
        //END SAME HEIGHT FUNCTION

        // add see more in mega menu brands dropdown
        $(".link_for_brands .mega_dropdown").append("<a class='see_more_brand_link' href='https://footcareuk.com/product-category/brand/'>See more</a>");

        var set_col_hights = function (el, extra) {
          if (typeof extra === "undefined") {
            extra = 0;
          }
          // Get an array of all element heights
          var elementHeights = $(el).map(function () {
            return $(this).outerHeight(true);
          }).get();
          //console.log(elementHeights);
          // Math.max takes a variable number of arguments
          // `apply` is equivalent to passing each height as an argument
          var maxHeight = Math.max.apply(null, elementHeights);

          // Set each height to the max height
          $(el).height(maxHeight + extra);
        };

        //START CUSTOM POPUPS
        var pop_over_box,
          pop_over_box_width,
          document_width_for_pop_over = $(window).width(),
          pop_over_box_move = function () {
            TweenMax.to("#grey_page_cover", 0.5, {
              top: $(window).scrollTop()
            });
            $("body, html").css("overflow", "hidden");
          },
          do_popup_layout = function () {
            //pop_up_remove_me glyphicon glyphicon-remove
            $("body").append('<div id="grey_page_cover"><div id="pop_over_box"><div class="pop_up_remove_me"></div><div class="popup_content_here"></div></div></div>');
            pop_over_box = $("#pop_over_box");

            if (Modernizr.mq('(max-width: 991px)')) {
              pop_over_box_width = "90%";
            } else {
              pop_over_box_width = "70%";
            }

            TweenMax.set($("#grey_page_cover"), {
              position: "absolute",
              top: "0",
              height: $(window).innerHeight() + "px",
              width: "100%",
              background: "rgba(0, 0, 0, 0.37)"
            });
            TweenMax.set($(".popup_content_here", pop_over_box), {
              overflow: "auto",
              height: "100%"
            });
            TweenMax.set($(".pop_up_remove_me", pop_over_box), {
              position: "absolute",
              backgroundImage: "url('" + site_url + "wp-content/themes/surge/assets/images/close.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "12px 11px",
              width: "70px",
              height: "70px",
              right: "31px",
              top: "31px",
              cursor: "pointer",
              zIndex: 999,
              color: "#ADD138"
            });
            TweenMax.set(pop_over_box, { // background
              position: "absolute",
              textAlign: "left",
              left: "50%",
              top: "2.5%",
              height: "95%",
              width: pop_over_box_width,
              zIndex: 999,
              backgroundColor: "#fff",
              padding: "2% 1%",
              xPercent: -50
            });
          },
          set_up_pop_over_box = function () {
            $(window).on("scroll.popover_scroll", pop_over_box_move);
            $(window).on("resize.popover_resize", function () {
              if (document_width_for_pop_over !== $(window).width()) { // resize / scroll was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
                document_width_for_pop_over = $(window).width();
                set_up_pop_over_box();
              }
            });
            do_popup_layout();
            pop_over_box_move();
          },
          popup_set_html = function (the_popup_html) {
            $(".popup_content_here", pop_over_box).html(the_popup_html);
          },
          remove_the_popup = function () {
            $(window).off("scroll.popover_scroll");
            $(window).off("resize.popover_resize");
            $("body, html").css("overflow", "inherit");
            $("#grey_page_cover").remove();
          };
        $(document).on("click", ".pop_up_remove_me, #grey_page_cover", function (e) {
          // stop child elements triggering it
          if (e.target !== e.currentTarget) { // http://stackoverflow.com/a/36876862/2129024
            return;
          }
          remove_the_popup();
          $("body, html").css("overflow", "visible");
        });
        // call with set_up_pop_over_box()
        // and then pass html into the popup with popup_set_html(the_popup_html)
        // then use remove_the_popup() to close it;
        //END CUSTOM POPUPS

        $(document).on("click", "#place_order", function () {
          $(this).addClass("order_disabled");
        });

        var scroll_animation;

        function goToByScroll(location, offset_top, speed) {
          if (typeof scroll_animation !== "undefined") {
            scroll_animation.kill();
          }
          scroll_animation = new TimelineMax();
          scroll_animation.to($(window), speed, {
            scrollTo: {
              y: location,
              offsetY: offset_top,
              autoKill: true
            },
            ease: Power3.easeOut
          });
        }

        //BEGIN WC MINI CART
        var number_of_items_in_cart, tl, mini_cart_height = $('.widget_shopping_cart').outerHeight(true);
        //console.log("mini_cart_height: "+mini_cart_height);
        function SURGE_hover_mini_cart() {
          if (Modernizr.mq('(min-width: 768px)')) {
            $('.basket_area').hover(function () {
              if (typeof tl !== "undefined") {
                tl.kill();
              }
              tl = new TimelineMax({
                delay: 0.0
              });
              tl.set(".widget_shopping_cart", {
                  autoAlpha: 0.0,
                  height: "0px"
                })
                .to(".widget_shopping_cart", 1.1, {
                  autoAlpha: 1.0,
                  height: mini_cart_height + "px",
                  zIndex: "6",
                  ease: Expo.easeOut,
                  y: 0
                });
            }, function () {
              if (typeof tl !== "undefined") {
                tl.kill();
              }
              tl = new TimelineMax({
                delay: 0.0
              });
              tl.to(".widget_shopping_cart", 1.1, {
                autoAlpha: 0.0,
                height: "0px",
                zIndex: "-1"
              });
            });
          } else {
            $(document).on("click", ".basket_area", function () {
              window.location.href = $(".widget_shopping_cart .button.wc-forward").attr("href");
            });
          }
        }

        if (Modernizr.mq('(max-width: 991px)')) {
          $(".left_col").prepend($(".term-description"));
          $(".expandable_product_areas").after($(".move_down_on_mobile"));
          $(".move_down_on_mobile").wrap("<div class='row'></div>")
          $("#mobile_show_fliters").on("click", function () {
            var state = $(this).attr("data-clicked");
            if (state === "expanded") {
              $(this).attr("data-clicked", "collapsed");
              $(this).next(".filter_wrap").css({
                "visibility": "visible",
                "height": "auto",
                "overflow": "visible"
              });
            }
            if (state === "collapsed") {
              $(this).attr("data-clicked", "expanded");
              $(this).next(".filter_wrap").css({
                "visibility": "hidden",
                "height": "0px",
                "overflow": "hidden"
              });
            }
          });
        }

        $(".expand_title").on("click", function () {
          var that = this;

          $(this).closest(".expand_wrapper").find(".expand_area").slideToggle(100);
          var state = $(this).attr("data-clicked");
          if (state === "expanded") {
            $(this).attr("data-clicked", "collapsed");
          }
          if (state === "collapsed") {
            $(this).attr("data-clicked", "expanded");
          }
        });

        $(document).on("wc_fragments_refreshed", function () {
          // this only runs when the cart is updated...
          if (typeof BJLL !== "undefined") {
            BJLL.check(); // BJ lazy load start showing images
          }
          number_of_items_in_cart = $(".widget_shopping_cart .mini_cart_item").length;
          if (number_of_items_in_cart) {
            mini_cart_height = $('.widget_shopping_cart').outerHeight(true);
            new SURGE_hover_mini_cart();
            $(".basket_area").append("<span class='number_of_items_in_cart'>" + number_of_items_in_cart + "</span>");
          }
        });

        // this will run every time the page is loaded but the if statment will return false if the cart was updated and will then not run but the above on function will take over.
        number_of_items_in_cart = $(".widget_shopping_cart .mini_cart_item").length;
        if (number_of_items_in_cart) {
          $(".basket_area").append("<span class='number_of_items_in_cart'>" + number_of_items_in_cart + "</span>");
        }
        if (mini_cart_height > 50) {
          new SURGE_hover_mini_cart();
        }
        //END WC MINI CART

        //BEGIN MOBILE SEARCH BAR
        $(document).on("click", ".yith_woocommerce_ajax_search, #yith-searchsubmit", function () {
          var search_field = $("input.swal2-input").text();
          if (Modernizr.mq('(max-width: 767px)')) {
            $(".search_modal").css("display", "flex");
          }
        });

        $(document).on("click", ".search_modal .close_button", function () {
          $(".search_modal").css("display", "none");
        });
        //END MOBILE SEARCH BAR

        //BEGIN WC TWEAKS
        $(".woocommerce-cart .cart_item").each(function () {

          $(this).find(".product-quantity").find(".quantity").append("<div class='top_arrow'></div><div class='bottom_arrow'></div>");

          $(this).find(".product-quantity").find(".quantity").find(".top_arrow").click(function () {
            var current_quantity = parseInt($(this).prev().attr("value"));
            $(this).prev().attr("value", parseInt(current_quantity) + 1);
          });

          $(this).find(".product-quantity").find(".quantity").find(".bottom_arrow").click(function () {
            var current_quantity = parseInt($(this).prev().prev().attr("value"));
            if (current_quantity <= 1) {
              $(this).prev().prev().attr("value", 1);
            } else {
              $(this).prev().prev().attr("value", parseInt(current_quantity) - 1);
            }
          });
        });

        $('.woocommerce-cart input[name="update_cart"]').removeAttr("disabled");
        $(".woocommerce-cart #coupon_code").attr("placeholder", "Voucher code");
        $(".woocommerce-cart .checkout-button").replaceWith('<a href="/checkout/" class="checkout-button button alt wc-forward"><span>Continue</span><img src="/wp-content/themes/surge/assets/images/continue_to_delivery.png"/></a>');

        $(".product_controls .edit").click(function () {
          var swal_this = this;

          swal({ // works!
            title: 'Are you sure?',
            text: "This will remove the product from your basket and take you back to the product page.",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
          }).then(function () {

            window.location = $(swal_this).attr("data-href");

          }, function (dismiss) {

            window.location = $(swal_this).attr("data-href");

          });
        });

        $(".product_controls .remove").click(function () {
          var swal_this = this;

          swal({ // works!
            title: 'Are you sure?',
            text: "This will remove the product from your basket.",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
          }).then(function () {

            window.location = $(swal_this).closest(".cart_item").find("a.remove").attr("href");

          }, function (dismiss) {

            window.location = $(swal_this).closest(".cart_item").find("a.remove").attr("href");

          });
        });

        $(".home .reviews_page .the_stars").click(function () {
          return false;
        });

        if (Modernizr.mq('(min-width: 767px)')) {
          TweenMax.set($(".home .rating"), {
            scale: 0.65,
            transformOrigin: "right center",
          });
        } else {
          TweenMax.set($(".home .rating"), {
            scale: 0.70
          });
        }
        //END WC TWEAKS

        //BEGIN MEGA MENU
        var mega,
        mega_open = function (mega_this) {
          if (!$(mega_this).is(".open")) {
            if (typeof mega !== "undefined") {
              mega.kill();
            }
            var mega_menu_height = $(".mega_dropdown", mega_this).outerHeight(true);
            mega = new TimelineMax({
              delay: 0.0
            });
            mega.set($("span.parent_text", mega_this), {
                background: "#3b4395"
              }, 0)
              .set($("> a, > span", mega_this), {
                color: "#fbba00"
              }, 0);
            if (Modernizr.mq('(min-width: 767px)')) {
              mega.set($(".mega_dropdown", mega_this), {
                height: "0px",
                autoAlpha: 0.0
              }, 0);
            } else {
              mega.set($(".mega_dropdown", mega_this), {
                  height: "0px",
                  autoAlpha: 0.0,
                  position: "relative",
                  top: "0"
                }, 0)
                .set($(mega_this).prev(".close_menu"), {
                  y: 125
                }, 0)
                .set($(mega_this).closest(".SURGE_mega_menu"), {
                  height: "inherit"
                }, 0)
                .to($(mega_this).prev(".close_menu"), 0.5, {
                  y: 21,
                  autoAlpha: 1.0,
                  display: "block",
                  rotation: 180
                }, 0);
            }

            mega.to($(".mega_dropdown", mega_this), 0.5, {
              zIndex: "99",
              height: mega_menu_height,
              autoAlpha: 1.0,
              ease: Expo.easeOut
            }, 0);
            $(mega_this).addClass("open");
          }
        },
        mega_close = function (mega_this) {
          $(mega_this).removeClass("open");
          if (typeof mega !== "undefined") {
            mega.kill();
          }
          mega = new TimelineMax({
            delay: 0.0
          });
          mega.set($.merge($("span.parent_text, > a, > span, .mega_dropdown", mega_this), $(mega_this).prev(".close_menu")), {
            clearProps: "all"
          });
        };

        var mega_this, mobile_menu_height = $("#SURGE_mega_menu_1").outerHeight(true), document_width;

        $(document).ready(function () {
          document_width = $(window).width();
        });

        var mega_events = function () {
          if (document_width !== $(window).width()) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
            document_width = $(window).width();
            TweenMax.set($("#SURGE_mega_menu_1"), {
              clearProps: "height"
            });
            mobile_menu_height = $("#SURGE_mega_menu_1").outerHeight(true);
            //alert("in mega_events");
            mega_close(".is_dropdown");
            //if (!navigator.userAgent.match(/Mobi/)) {
            if (Modernizr.mq('(min-width: 767px)')) {
              $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once
              $('.is_dropdown').unbind("mouseenter");
              $('.is_dropdown').unbind("mouseleave");
              $('.is_dropdown')
                .mouseenter(function () {
                  mega_this = this;
                  //if (count_this === 0) {
                  mega_open(mega_this);
                  //}
                  //count_this++;
                  //alert("mouseenter");
                  if (Modernizr.mq('(min-width: 1199px)')) {
                    // code that will put the dropdown roughly below its hovered parent li
                    var position = $(mega_this).position();
                    var rightOffset = $(mega_this).closest(".SURGE_mega_menu").width() - (position.left + $(mega_this).width());
                    //console.log("rightOffset: "+rightOffset);
                    //console.log("position.left: "+position.left);
                    var difference = (position.left - rightOffset) + ($(mega_this).width() / 2);

                    difference = Math.abs(difference); // removing negative -
                    //console.log("difference: "+difference);
                    if (difference < 150) {
                      $(".mega_dropdown", mega_this).css({
                        left: "17%",
                        right: "17%"
                      });
                    } else if (position.left > rightOffset) {
                      $(".mega_dropdown", mega_this).css({
                        left: "28%",
                        right: "2.3%"
                      });
                    }
                    //console.log("menu width: "+ $(".mega_dropdown", this).width());
                  }
                })
                .mouseleave(function () {
                  //alert("mouseleave");
                  mega_this = this;
                  mega_close(mega_this);
                });
            } else {
              $('.is_dropdown').unbind("mouseenter");
              $('.is_dropdown').unbind("mouseleave");
              $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once
              $('.is_dropdown .parent_text').on("click.mega_events", function () {
                //alert("click");
                mega_close(".is_dropdown");
                mega_this = $(this).closest(".is_dropdown");
                if (!$(mega_this).is(".open")) {
                  mega_open(mega_this);
                  goToByScroll(mega_this, 40, 0.8);
                }
              });
            }
          }
        };
        mega_events();
        $(window).on("resize", mega_events);

        var mobile_menu;
        $('.close_menu, .navbar-toggle').on("click", function () {
          mega_close($(".is_dropdown"));
          if ($(this).is(".navbar-toggle")) {
            if (Modernizr.mq('(max-width: 767px)')) {
              if ($(this).hasClass("collapsed")) {
                if (typeof mobile_menu !== "undefined") {
                  mobile_menu.kill();
                }
                mobile_menu = new TimelineMax({
                  delay: 0.0
                });
                mobile_menu.set($("#SURGE_mega_menu_1"), {
                    height: "0",
                    margin: "-23px 0",
                    position: "relative"
                  }, 0)
                  .to($("#SURGE_mega_menu_1"), 1.0, {
                    height: mobile_menu_height,
                    margin: "0",
                    autoAlpha: 1.0,
                    ease: Expo.easeOut
                  });
                $(this).removeClass("collapsed");
              } else {
                if (typeof mobile_menu !== "undefined") {
                  mobile_menu.kill();
                }
                mobile_menu = new TimelineMax({
                  delay: 0.0
                });
                mobile_menu.to($("#SURGE_mega_menu_1"), 1.0, {
                  height: "0",
                  margin: "-23px 0",
                  autoAlpha: 0.0,
                  ease: Expo.easeOut
                });
                $(this).addClass("collapsed");
              }
            }
          }
          if ($(this).is(".close_menu")) {
            goToByScroll("html", 0, 0.8);
          }
        });

        $(window).load(function () { // wait for images to load
          // set cols the same height
          var highest = null,
            hi = 0,
            collection = $(),
            process_collection = false,
            remove_hrs = true;
          if (Modernizr.mq('(min-width: 991px)')) {
            $(".mega_col").each(function () {
              if ($(this).next().is("hr")) {
                collection = $.merge(collection, $(this));
                $(this).css({
                  "padding-right": "0",
                  "background": "none"
                });
                process_collection = true;
              } else {
                collection = $.merge(collection, $(this));
              }
              if (process_collection) {
                $(collection).each(function (i, el) {
                  if ($("> *", this).first().is("h4")) {
                    remove_hrs = false;
                  }
                  ////
                  var h = $(this).outerHeight(true);
                  if (h > hi) {
                    hi = h;
                  }
                });
                if (remove_hrs) {
                  $("> hr:first-of-type", collection).remove();
                  hi = hi - 25;
                }
                $(collection).height(hi);
                // resets
                collection = $();
                process_collection = false;
                remove_hrs = true;
                hi = 0;
              }
            });
          } else {
            $(".mega_dropdown > hr").remove();
          }
          // remove end hr(s)
          //$(".mega_col hr").last().remove();
          $(".mega_dropdown hr:last-child").remove();
        });

        // set top level label wrappers to the size of the text within them so that they dont jiggle about on hover (when the text goes bold it will make the text bigger)
        // this will stop the text from pushing on the next one
        if (Modernizr.mq('(min-width: 767px)')) {
          $(".SURGE_mega_menu .parent_li").each(function () {
            $(this).width($(this).width());
            $(".parent_text", this).attr("data-original_width", $(".parent_text", this).width());
          });
          $('.parent_li') // here we are takeing the increase of width from the padding on the text element to stop it overflowing its parent
            .mouseenter(function () {
              var current_side_padding = $(".parent_text", this).css("padding-left").replace("px", ""), // will be the same as padding right...
                hover_parent_text_width = $(".parent_text", this).width(),
                origin_width = $(".parent_text", this).attr("data-original_width"),
                added_with = hover_parent_text_width - origin_width,
                added_with_divided = added_with / 2;
              new_side_padding = current_side_padding - added_with_divided;
              $(".parent_text", this).css({
                "padding-left": new_side_padding + "px",
                "padding-right": new_side_padding + "px"
              });
            })
            .mouseleave(function () {
              TweenMax.set($(".parent_text", this), {
                clearProps: "all"
              });
            });
        }
        //END MEGA MENU

        //BEGIN MAILING LIST
        $(document).ready(function () {

          $(document).on("click", ".feature_box.third a, #review_us button[type='submit'], .reviews_page .box_heading", function () {

            if ($(this).is(".reviews_page .box_heading")) {
              $("form#review_us").slideToggle();
            }
            if ($(this).is("#review_us button[type='submit']")) {

              var form = $("form#review_us"),
                star = $('input[name=stars]:checked', form).val() || '',
                name = $('#name', form).val() || '',
                email = $('#email', form).val() || '',
                location = $('#location', form).val() || '',
                comment = $('#comment', form).val() || '';

              if (star.trim() === "") {
                swal("Please select your star rating", "Click one of the stars at the top", "warning");
                return false;
              }
              if ($.isNumeric(star.trim()) === false || parseInt(star.trim(), 10) > 5) {
                swal("Invalid", "Nice try.", "warning");
                return false;
              }
              if (name.trim() === "") {
                swal("Missing name", "Please enter your name into the text box", "warning");
                return false;
              }
              if (email.trim() !== "") { // if something is in it
                if (email.trim().toLowerCase().indexOf("@") === -1 || email.trim().toLowerCase().indexOf(".") === -1) {
                  swal("Invalid email", "Please enter a real email", "warning");
                  return false;
                }
              }
              if (comment.trim() === "") {
                swal("Missing review write-up", "Please enter your review into the text area", "warning");
                return false;
              }
              if (comment.trim().length < 60) {
                swal("Please be a bit more expressive", "Your review is very short please expand it.", "warning");
                return false;
              }
              if (comment.trim().length > 2000) {
                swal("Character limit", "The review text has a 2000 character limit", "warning");
                return false;
              }

              preloader.active(true);

              $.ajax({
                type: "POST",
                url: MyAjax.ajaxurl,
                data: {
                  "action": "adding_review",
                  "postCommentNonce": MyAjax.postCommentNonce,
                  "star": star,
                  "name": name,
                  "email": email,
                  "location": location,
                  "comment": comment
                },
                dataType: "json",
                success: function (response) {
                  preloader.active(false);
                  $(form)[0].reset();
                  if (response.error) {
                    the_title = "Error";
                    the_type = "warning";
                    response = response.error;
                  } else {
                    the_title = "Success";
                    the_type = "success";
                  }
                  swal({
                    title: the_title,
                    text: response,
                    timer: 3800,
                    showConfirmButton: false,
                    type: the_type
                  });
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                  preloader.active(false);
                  swal({
                    title: "Warning",
                    text: "The website is having problems processing the request. Full error: " + XMLHttpRequest.responseText +
                      " | " + textStatus + " | " + errorThrown,
                    showConfirmButton: false,
                    type: "warning",
                    timer: 3800
                  });
                }
              });
              return false;
            }
            if ($(this).is(".feature_box.third a")) {

              var swalHTML = document.createElement("div");
              swalHTML.innerHTML = "<form id=\"mailing_list\" class=\"clearfix\">" +
              "  <div class=\"form-group\">" +
              "    <input type=\"text\" class=\"form-control\" id=\"customer_name\" placeholder=\"Name\">" +
              "  </div>" +
              "  <div class=\"form-group\">" +
              "    <input type=\"email\" class=\"form-control\" id=\"customer_email\" placeholder=\"Email address\">" +
              "  </div>" +
              "</form>" +
              "<p>(We will never ever share your email address)</p>";

              swal({
                title: "Sign up now for exclusive discounts, news & features",
                content: swalHTML,
              }).then(function () {
                var name = $("#mailing_list #customer_name").val().trim();
                var email = $("#mailing_list #customer_email").val().trim();
                if (name === "" || email === "") {
                  swal.showInputError("Please type your name and email into the fields above.");
                  return false;
                } else {
                  $.ajax({
                    type: "POST",
                    url: MyAjax.ajaxurl,
                    data: {
                      "action": "mailing_list",
                      "postCommentNonce": MyAjax.postCommentNonce,
                      "name": name,
                      "email": email
                    },
                    dataType: "json",
                    success: function (response) {
                      swal({
                        title: "Success",
                        text: response,
                        timer: 3800,
                        showConfirmButton: false,
                        type: "success"
                      });
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                      swal({
                        title: "warning",
                        text: "The website is having problems processing the request. Full error: " + XMLHttpRequest.responseText +
                          " | " + textStatus + " | " + errorThrown,
                        showConfirmButton: false,
                        type: "warning",
                        timer: 3800
                      });
                    }
                  });
                }
              });
            }
          });

          // $(document).on("click", "#place_order", function() {
  
          //   console.log("in");
  
          //   if ($("#my_custom_checkout_field input[type=checkbox]").is(':checked')) {
  
          //     console.log("Its checked");
  
          //     var name = $("#billing_first_name").val().trim() + ' ' + $("#billing_last_name").val().trim();
          //     var email = $("#billing_email").val().trim();
              
          //     $.ajax({
          //       type: "POST",
          //       url: MyAjax.ajaxurl,
          //       data: {
          //         "action": "mailing_list",
          //         "postCommentNonce": MyAjax.postCommentNonce,
          //         "name": name,
          //         "email": email
          //       },
          //       dataType: "json",
          //       success: function (response) {
          //         console.log("success");
          //       },
          //       error: function (XMLHttpRequest, textStatus, errorThrown) {
          //         console.log("error");
          //       }
          //     });
          //   }
  
          //   return false;
          // });

          $(document).ready(function() {
            if (window.location.href.indexOf("trigger_signup=true") > -1 ) {

              var swalHTML = document.createElement("div");
              swalHTML.innerHTML = "<form id=\"mailing_list\" class=\"clearfix\">" +
              "  <div class=\"form-group\">" +
              "    <input type=\"text\" class=\"form-control\" id=\"customer_name\" placeholder=\"Name\">" +
              "  </div>" +
              "  <div class=\"form-group\">" +
              "    <input type=\"email\" class=\"form-control\" id=\"customer_email\" placeholder=\"Email address\">" +
              "  </div>" +
              "</form>" +
              "<p>(We will never ever share your email address)</p>";

              swal({
                title: "Sign up now for exclusive discounts, news & features",
                content: swalHTML,
              }).then(function () {
                var name = $("#mailing_list #customer_name").val().trim();
                var email = $("#mailing_list #customer_email").val().trim();
                if (name === "" || email === "") {
                  swal.showInputError("Please type your name and email into the fields above.");
                  return false;
                } else {
                  $.ajax({
                    type: "POST",
                    url: MyAjax.ajaxurl,
                    data: {
                      "action": "mailing_list",
                      "postCommentNonce": MyAjax.postCommentNonce,
                      "name": name,
                      "email": email
                    },
                    dataType: "json",
                    success: function (response) {
                      swal({
                        title: "Success",
                        text: response,
                        timer: 3800,
                        showConfirmButton: false,
                        type: "success"
                      });
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                      swal({
                        title: "warning",
                        text: "The website is having problems processing the request. Full error: " + XMLHttpRequest.responseText +
                          " | " + textStatus + " | " + errorThrown,
                        showConfirmButton: false,
                        type: "warning",
                        timer: 3800
                      });
                    }
                  });
                }
              });
            }
          });

          $(document).ready(function () {
            if (window.location.href.indexOf("full_name") > -1 && window.location.href.indexOf("email_addr") > -1 && window.location.href.indexOf("opt_in") > -1) {

              var url = window.location.href;

              var full_name_first_split = url.split('full_name=');
              var full_name_second_split = full_name_first_split[1].split('&');
              var full_name = full_name_second_split[0];
              var fixed_full_name = full_name.replace('%20', ' ');

              var email_addr_first_split = url.split('email_addr=');
              var email_addr_second_split = email_addr_first_split[1].split('&');
              var email_addr = email_addr_second_split[0];
              var fixed_email_addr = email_addr.replace('%40', '@');

              console.log(fixed_email_addr);

              var opt_in_first_split = url.split('opt_in=');
              var opt_in_second_split = opt_in_first_split[1].split('&');
              var opt_in = opt_in_second_split[0];

              console.log(opt_in);

              if (opt_in === "true") {

                var swalHTML = document.createElement("div");
                swalHTML.innerHTML = "<div class=\"thank_you_popup\" class=\"clearfix\">" +
                "  <p>" +
                "    <strong>You have now been added to our mailing list</strong>" +
                "  </p>" +
                "  <div class=\"foot_icon\">" +
                "    <img class=\"img-responsive\" src=\"https://footcareuk.com/wp-content/themes/surge/assets/images/foot_icon.png\" placeholder=\"Email address\">" +
                "  </div>" +
                "<p>(We will never ever share your email address)</p>" +
                "</div>"; 

                console.log(fixed_full_name);
                console.log(fixed_email_addr);

                swal({
                  title: "Thank you!",
                  content: swalHTML,
                }).then(function () {

                  $.ajax({
                    type: "POST",
                    url: MyAjax.ajaxurl,
                    data: {
                      "action": "mailing_list_confirmation",
                      "postCommentNonce": MyAjax.postCommentNonce,
                      "name": fixed_full_name,
                      "email": fixed_email_addr
                    },
                    dataType: "json",
                    success: function (response) {
                      console.log("success");
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                      console.log("error");
                      console.log(errorThrown);
                    }
                  });

                  // $.ajax({
                  //   type: "POST",
                  //   url: MyAjax.ajaxurl,
                  //   data: {
                  //     "action": "mailing_list_confirmation",
                  //     "name": fixed_full_name,
                  //     "email": fixed_email_addr
                  //   },
                  //   dataType: "json",
                  //   success: function (response) {
                  //     console.log("success");
                  //   },
                  //   error: function (XMLHttpRequest, textStatus, errorThrown) {
                  //     console.log("error");
                  //     console.log(errorThrown);
                  //   }
                  // });
                  // return false;
                });
              }
            }
          });

          // code for retrieving product quick view ui
          $(document).on("click", ".woocommerce .quick_view", function () {
            preloader.active(true);

            var product_ID = $(this).closest(".featured_product.woocommerce").attr("data-product_ID");

            // fist get cache busting nonce
            $.ajax({
              type: "GET",
              url: site_url + "cache-buster",
              dataType: "html",
              success: function (response) {
                var nonce = response;

                // retrive quick view
                $.ajax({
                  type: "POST",
                  url: MyAjax.ajaxurl,
                  data: {
                    "action": "product_cat_get_product_quick_view",
                    "nonce": nonce,
                    "product_ID": product_ID
                  },
                  dataType: "html",
                  success: function (response) {
                    preloader.active(false);
                    set_up_pop_over_box();
                    response = response.replace(/[$*$]{3}/g, '|'); // http://www.regextester.com/ see cheat sheet

                    popup_set_html(response);

                    // Variation Form
                    var form_variation = $('#pop_over_box .variations_form');

                    form_variation.wc_variation_form();
                    form_variation.trigger('check_variations');

                    $('.woocommerce-product-gallery').wc_product_gallery();
                  },
                  error: function (XMLHttpRequest, textStatus, errorThrown) {
                    preloader.active(false);
                    console.log(XMLHttpRequest.responseText + " | " + textStatus + " | " + errorThrown);
                    swal({
                      title: "Warning",
                      text: "The website is having problems processing the request. Full error: " + XMLHttpRequest.responseText +
                        " | " + textStatus + " | " + errorThrown,
                      showConfirmButton: false,
                      type: "warning",
                      timer: 3800
                    });
                  }
                });
                // END retrive quick view

              },
            });
            // END get cache busting nonce

            return false;
          }); // on click

        });
        //END MAILING LIST

        //START OWL CAROUSEL INITS

        // Top home slider
        if ($(".home_slider")[0]) {
          $(document).ready(function () {
            var carousel = $(".home_slider.owl-carousel");
            carousel.owlCarousel({
              autoplay: true,
              autoplayTimeout: 5000,
              autoplayHoverPause: true,
              loop: true,
              dots: true,
              items: 1
            });
          });
        }

        // Reviews slider
        if ($(".reviews_carousel")[0]) {
          $(document).ready(function () {
            var carousel = $(".reviews_carousel .owl-carousel");
            carousel.owlCarousel({
              autoplay: true,
              autoplayTimeout: 5000,
              autoplayHoverPause: true,
              loop: true,
              nav: true,
              responsiveClass: true,
              responsive: {
                0: {
                  items: 1
                },
                768: {
                  items: 2
                }
              }
            });
          });

          $(".reviews_carousel .owl_nav_custom").on("click", function() {
            if ($(this).hasClass("prev")) {
              $(".reviews_carousel button.owl-prev").trigger("click");
            } else if ($(this).hasClass("next")) {
              $(".reviews_carousel button.owl-next").trigger("click");
            }
          });
        }

        // Favourites slider
        if ($(".favourites_carousel")[0]) {
          $(document).ready(function () {
            var carousel = $(".favourites_carousel .owl-carousel");
            carousel.owlCarousel({
              autoplay: true,
              autoplayTimeout: 5000,
              autoplayHoverPause: true,
              loop: true,
              nav: true,
              responsiveClass: true,
              responsive: {
                0: {
                  items: 1
                },
                768: {
                  items: 2
                },
                991: {
                  items: 4
                }
              }
            });
          });

          $(".favourites_carousel .owl_nav_custom").on("click", function() {
            if ($(this).hasClass("prev")) {
              $(".favourites_carousel button.owl-prev").trigger("click");
            } else if ($(this).hasClass("next")) {
              $(".favourites_carousel button.owl-next").trigger("click");
            }
          });
        }

        // Favourites slider
        if ($(".search_carousel")[0]) {
          $(document).ready(function () {
            var carousel = $(".search_carousel .owl-carousel");
            carousel.owlCarousel({
              autoplay: true,
              autoplayTimeout: 5000,
              autoplayHoverPause: true,
              loop: true,
              nav: true,
              responsiveClass: true,
              responsive: {
                0: {
                  items: 1
                },
                768: {
                  items: 2
                },
                991: {
                  items: 4
                }
              }
            });
          });

          $(".search_carousel .owl_nav_custom").on("click", function() {
            if ($(this).hasClass("prev")) {
              $(".search_carousel button.owl-prev").trigger("click");
            } else if ($(this).hasClass("next")) {
              $(".search_carousel button.owl-next").trigger("click");
            }
          });
        }

        //END OWL CAROUSEL INITS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
