// Modernizr
import './scripts/extra/modernizr-custom.js';

// GSAP
import './scripts/extra/TweenMax.min.js';
import './scripts/extra/ScrollToPlugin.min.js';

// Owl Carousel
import './scripts/extra/owl.carousel.min.js';

// Sweetalert
import 'sweetalert';

// UI Elements
import './styles/main.scss';
import './scripts/main.js';